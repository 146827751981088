import {
  ChallengeSection,
  ParticipantSection as ParticipantSectionV1,
  ParticipantStep,
} from '@wix/ambassador-challenges-v1-participant/types';
import { getFirstAvailableStep } from '../components/ParticipantPage/Widget/views/utils';
import { isV3Section } from '../utils/isV3';
import type {
  Section,
  SectionWithSteps,
  Description,
  ParticipantSection,
} from '../types/v3Types';
import type { Section as SectionV3 } from '@wix/ambassador-online-programs-v3-section/types';

export const isSectionLocked = (section: ParticipantSectionV1): boolean => {
  return !!section?.transitions?.[0]?.waitingDate;
};

export const getFirstAvailableStepFromSection = (
  sections: ParticipantSectionV1[] = [],
): ParticipantStep => {
  let step: ParticipantStep;

  sections.forEach((section) => {
    if (step || isSectionLocked(section)) {
      return;
    }

    step = getFirstAvailableStep(section.steps);
  });

  return step;
};

export function getFirstAvailableSection(
  sections: ParticipantSectionV1[] = [],
) {
  return sections.find((section) => {
    return !isSectionLocked(section) && !sectionCannotBeOpenedInView(section);
  });
}

export function getFirstSection(sections: ParticipantSectionV1[] = []) {
  return getFirstAvailableSection(sections) || sections?.[0];
}

export const getSectionDescription = (section: Section): Description => {
  if (isV3Section(section)) {
    return (section as SectionV3).description;
  }
  return (section as ChallengeSection).settings?.description;
};

export const getSectionSteps = (section: SectionWithSteps) => {
  if (!section.steps) {
    console.warn('Section has no steps', section);
  }
  return section.steps;
};

export const getSectionTitle = (section: Section) => {
  return getSectionDescription(section)?.title;
};

export const getSectionDetails = (section: Section) => {
  return getSectionDescription(section)?.details;
};

export const getSectionMedia = (section: Section) => {
  if (isV3Section(section)) {
    const { image, video } = (section as SectionV3).description;
    if (image || video) {
      return { image, video };
    }
    return;
  }
  return (section as ChallengeSection).settings?.description?.media;
};

export const setDescription = (section: Section, description: Description) => {
  if (isV3Section(section)) {
    (section as SectionV3).description.details = description.details;
  } else {
    (section as ChallengeSection).settings.description = description;
  }
};

export const getWaitingDate = (section: ParticipantSection) => {
  if (isV3Section(section)) {
    return null;
  }
  return (section as ParticipantSectionV1).transitions?.[0]?.waitingDate;
};

export const sectionCannotBeOpenedInView = (section: ParticipantSection) => {
  return !section || !section.id || section.withoutDetails;
};

export const getWaitingStart = (section: ParticipantSection) => {
  return getWaitingDate(section)?.startDate;
};
